<template>
  <div class="page-bg">
    <div class="demo-main">
      <div class="demo-title">Smart Spoken <br> English Assessment Demo</div>
      <div class="demo-little-title">Basic Question Types</div>
      <div class="demo-question">
        <div class="eval-item" @click="$router.push('/demo-phoneme')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/phonetic.png"></div>
          <div class="item-title">Phoneme speech assessment</div>
          <ul>
            <li>phoneme mistake detection</li>
            <li>phoneme-level feedback</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-word')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/words.png"></div>
          <div class="item-title">Word speech assessment</div>
          <ul>
            <li>phoneme mistake detection</li>
            <li>syllable stress detection</li>
            <li>spelling and sound alignment</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-sentence')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/sentence.png"></div>
          <div class="item-title">Sentence speech assessment</div>
          <ul>
            <li>real-time feedback</li>
            <li>linking detection</li>
            <li>word-level feedback</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-paragraph')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/paragraph.png"></div>
          <div class="item-title">Paragraph speech assessment</div>
          <ul>
            <li>phoneme-level feedback</li>
            <li>real-time feedback</li>
            <li>word-level feedback</li>
            <li>linking detection</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-wordmistake')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/wordcheck.png"></div>
          <div class="item-title">Word mistake detection assessment</div>
          <ul>
            <li>phoneme mistake detection</li>
            <li>phoneme-level feedback</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-sentencemistake')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/sentencecheck.png"></div>
          <div class="item-title">Sentence mistake detection assessment</div>
          <ul>
            <li>word-level feedback</li>
            <li>word mistake detection</li>
          </ul>
        </div>
      </div>
      <div class="demo-little-title">Advanced Question Types</div>
      <div class="demo-question">
        <div class="eval-item" @click="$router.push('/demo-oralquestions')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/scenario.png"></div>
          <div class="item-title">Oral questions</div>
          <ul>
            <li>overall, fluency, integrity and accuracy scores</li>
            <li>score based on key points and unkey points</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-story')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/storytelling.png"></div>
          <div class="item-title">Story retelling assessment</div>
          <ul>
            <li>overall, fluency, integrity and accuracy scores</li>
            <li>score based on key points and unkey points</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-oralcomposition')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/pic-talk.png"></div>
          <div class="item-title">Oral composition assessment</div>
          <ul>
            <li>overall, fluency, integrity and accuracy scores</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-recite')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/word-follow.png"></div>
          <div class="item-title">Recite speech assessment</div>
          <ul>
            <li>real-time feedback</li>
            <li>word-level feedback</li>
            <li>skip and repeat recite</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-choise')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/choise.png"></div>
          <div class="item-title">Choice assessment</div>
          <ul>
            <li>spoken-out answer recognition</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-freechoise')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/selectedreadings.png"></div>
          <div class="item-title">Free-choice speech assessment</div>
          <ul>
            <li>free choice for reading aloud</li>
          </ul>
        </div>
        <div class="eval-item" @click="$router.push('/demo-freedom')">
          <div class="item-icon"><img src="~@/assets/images/soe_demo/freedom.png"></div>
          <div class="item-title">Freedom assessment</div>
          <ul>
            <li>free reading recognition</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SoeDemo',
  data () {
    return {

    }
  }
}
</script>

<style scoped lang="less">
.page-bg {
  display: flex;
  padding: 30px 0;
}
.demo-main {
  width: 85%;
  padding: 0 10px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 15px 0 hsla(0,0%,79.6%,.5);
  border-radius: 10px;
  .demo-title {
    padding: 20px 0 30px;
    color: #000;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  .demo-little-title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0;
    color: #2f29df;
    line-height: 20px;
  }
  .demo-question {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 30px;
    .eval-item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 10px 10px;
      margin-top: 10px;
      background: transparent;
      border-radius: 8px;
      cursor: pointer;
      .item-icon {
        height: 30px;
        margin-right: 5px;
        img {
          height: 30px;
        }
      }
      .item-title {
        width: 88%;
        line-height: 18px;
        font-weight: 500;
        font-size: 16px;
        color: #333;
        word-break: break-word;
        white-space: pre-wrap;
      }
      ul {
        li {
          padding-top: 5px;
          line-height: 24px;

          font-size: 16px;
          color: #333;
          list-style: disc inside;
        }
      }
    }
    .eval-item:hover {
      background: rgba(255,255,255,.1);
      box-shadow: 0 0 10px 0 hsla(0,0%,89%,.8);
    }
  }
}
</style>